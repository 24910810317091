<template>
  <GlPageWrap
    class="pp-page__score-requests"
    hide-title-on-mobile
    title="Score requests"
  >
    <template #actions>
      <GlCoinSelect class="m-fullwidth" />
    </template>
    <MainTabs
      :active-tab="activeTab"
      :tabs-list="tabsList"
      @handleActiveTab="handleActiveTab($event)"
    />
    <TxScoring v-if="activeTab === 'tx-scoring'" />
    <AddressScoring v-if="activeTab === 'address-scoring'" />
  </GlPageWrap>
</template>

<script>
// Components
import GlCoinSelect from "@/components/gl-coin-select";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import TxScoring from "@/pages/api-requests/components/TxScoring";
import AddressScoring from "@/pages/api-requests/components/AddressScoring";
import MainTabs from "@/components/pp/pp-main-tabs"

export default {
  components: {
    TxScoring,
    GlPageWrap,
    GlCoinSelect,
    AddressScoring,
    MainTabs,
  },
  data() {
    return {
      activeTab: 'tx-scoring',
      tabsList: [
        {
          id: 'tx-scoring',
          label: 'Transaction Scoring',
        },
        {
          id: 'address-scoring',
          label: 'Address Scoring',
        },
      ],
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val && val.query.tab) {
          this.$nextTick(() => {
            this.activeTab = val.query.tab
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleActiveTab(tab) {
      this.activeTab = tab
      this.$router.replace({ name: 'api-requests', query: { tab: tab } })
    },
  }
}
</script>

<style>
.pp-page__score-requests {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}
.pp-page__score-requests .pp-pagination-table {
  padding: 16px 24px 0 24px;
}

.pp-score-requests__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  backdrop-filter: blur(4px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

@media (max-width: 767px) {
  .pp-page__score-requests {
    padding-top: 56px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

.pp-score-requests__component {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 24px 0 14px 0;
  background-color: var(--white);
  border-radius: 0 0 12px 12px;
  box-shadow: none;
  border: 1px solid var(--cotton-grey-f-5);
  border-top: none;
  position: relative;
}
.pp-score-requests__component .pp-table__requests {
  flex: 1 0 auto;
}


/* PP Table */
.pp-table__requests {
  overflow: auto !important;
}
.pp-table__requests::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.pp-table__requests .o-table__th {
  padding: 0 16px 16px 16px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--space-cadet) !important;
  text-transform: none !important;
  white-space: nowrap !important;
}
.pp-table__requests .o-table__th:nth-child(3),
.pp-table__requests .o-table__th:nth-child(4),
.pp-table__requests .o-table__th:nth-child(5),
.pp-table__requests .o-table__th:nth-child(6) {
  text-align: right;
}
.pp-table__requests .o-table__td {
  padding: 8px 16px 8px 16px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--space-cadet) !important;
  text-transform: none !important;
}
.pp-table__requests .o-table__td:nth-child(3),
.pp-table__requests .o-table__td:nth-child(4),
.pp-table__requests .o-table__td:nth-child(5),
.pp-table__requests .o-table__td:nth-child(6) {
  text-align: right;
}
.pp-date__time,
.pp-monitoring__table-balance-price,
.pp-monitoring__table-amount-received-price {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--dark-grey-6-e) !important;
}
.pp-table__requests .gl-badge-info {
  gap: 0;
}

@media (max-width: 767px) {
  .pp-table__requests .o-table__th {
    padding: 0 8px 16px 8px !important;
  }
  .pp-table__requests .o-table__td {
    padding: 8px !important;
  }
  .pp-page__score-requests .page-wrapper__header {
    margin: 0;
    padding: 16px 16px 8px 16px;
    background-color: var(--white) !important;
  }
  .pp-page__score-requests .pp-tabs {
    margin-left: 0;
    margin-right: 0;
    padding: 0 16px;
  }
  .pp-score-requests__component {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    padding: 16px 0;
    border-radius: 12px;
  }
}
</style>
