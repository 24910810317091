<template>
  <div class="pp-tabs">
    <div class="pp-tabs__left">
      <div
        v-for="(item, index) in tabsList"
        :key="index"
        class="pp-tabs__item"
        :class="{'pp-tabs__item--active': activeTab === item.id}"
        @click="$emit('handleActiveTab', item.id)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabsList: {
      type: Array,
      default: () => {},
    },
    activeTab: {
      type: String,
      default: '',
    }
  },
}
</script>

<style scoped>
.pp-tabs {
  display: flex;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  border-radius: 12px 12px 0 0;
}
.pp-tabs__left {
  flex: 1 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-flow: column;
  max-width: 100%;
  overflow: auto;
}
.pp-tabs__left::-webkit-scrollbar {
  height: 4px;
}
.pp-tabs__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-grey-6-e);
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease;
}
.pp-tabs__item--active {
  font-weight: 600;
  color: var(--space-cadet);
  cursor: default;
}
.pp-tabs__item::after {
  content: "";
  width: 0%;
  height: 3px;
  background-color: var(--soft-blue);
  position: absolute;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
}
.pp-tabs__item--active::after {
  width: 100%;
}

@media (max-width: 767px) {
  .pp-tabs {
    margin: 0 -10px 8px -10px;
    padding: 56px 16px 0 16px;
    border: none;
    border-radius: 0;
  }
  .pp-tabs__left {
    grid-template-columns: repeat(auto-fill, 160px);
  }
  .pp-tabs__item {
    min-width: 160px;
  }
}
</style>